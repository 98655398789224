<template>
    <el-table
        v-if="tableVisible"
        ref="table"
        :data="data"
        v-loading="$waiting.is('loading')"
        size="mini"
        @sort-change="sortChange"
        @current-change="handleCurrentChange"
        @selection-change="handleSelectionChange"
        :row-class-name="tableRowClassName"
        highlight-current-row
    >
        <el-table-column v-if="selectedType === 'matched'" type="selection" width="42" align="center" />
        <el-table-column v-if="selectedType === 'matched'" width="40" align="right">
            <template slot-scope="scope">
                <MatchBullet :match-level="123" :is-factoring="scope.row.isFactoring" />
            </template>
        </el-table-column>
        <el-table-column v-if="selectedType === 'matched'" width="40" align="right">
            <template slot-scope="scope">
                <MatchBullet :match-level="999" :has-debt="scope.row.hasDebt" />
            </template>
        </el-table-column>
        <el-table-column v-if="selectedType === 'unmatched' || selectedType === 'unidentified'" width="40" align="right">
            <template slot-scope="scope">
                <MatchBullet :match-level="scope.row.matchLevel" :has-debt="scope.row.hasDebt" />
            </template>
        </el-table-column>
        <el-table-column prop="clientName" label="Klient" show-overflow-tooltip sortable />
        <el-table-column prop="customerName" label="Avsändare" show-overflow-tooltip sortable />
        <el-table-column prop="bookingDate" label="Datum" width="90">
            <template slot-scope="scope">
                {{ formatDate(scope.row.bookingDate) }}
            </template>
        </el-table-column>
        <el-table-column prop="text" label="Text" show-overflow-tooltip />
        <el-table-column prop="amount" label="Belopp" width="140" align="right" sortable>
            <template slot-scope="scope">
                {{ scope.row.amount | swedishNumberFormat }}
            </template>
        </el-table-column>
        <el-table-column width="120" align="right">
            <template slot-scope="scope">
                <el-button-group>
                    <el-tooltip v-if="scope.row.comment != ''" transition="none" effect="light" :content="scope.row.comment" placement="top" :enterable="false" :open-delay="500">
                        <el-button class="p-1" type="">
                            <span>
                                <i class="fa-regular fa-comment text-base" style="color: #317e0d;" />
                            </span>
                        </el-button>
                    </el-tooltip>
                    <el-tooltip v-if="scope.row.unidentified" transition="none" effect="light" content="Ta bort markering som oidentifierad" placement="top" :enterable="false" :open-delay="500">
                        <el-button v-if="selectedType === 'unmatched'" @click="clearAsUnidentified(scope.row.id)" type="" class="p-1">
                            <span>
                                <i class="far fa-question text-base" />
                                <i class="fa fa-light fa-ban fa-stack-2x text-danger" style="color:red;" />
                            </span>
                        </el-button>
                    </el-tooltip>
                    <el-tooltip v-if="!scope.row.unidentified" transition="none" effect="light" content="Markera som oidentifierad" placement="top" :enterable="false" :open-delay="500">
                        <el-button v-if="selectedType === 'unmatched'" @click="markAsUnidentified(scope.row.id)" type="" class="p-1">
                            <i class="far fa-question text-base" style="color: #e31616;" />
                        </el-button>
                    </el-tooltip>
                    <el-tooltip transition="none" effect="light" content="Visa fakturor som kan matcha" placement="top" :enterable="false" :open-delay="500">
                        <el-button @click="showMatchedInvoices(scope.row)" type="" class="p-1">
                            <i class="fa-regular fa-radar text-base" style="color: #1235a5;" />
                        </el-button>
                    </el-tooltip>
                    <!-- <router-link :to="'bank_files/' + scope.row.id">
                        <el-tooltip transition="none" effect="light" content="Visa detaljer om betalningen" placement="top" :enterable="false" :open-delay="500">
                            <el-button type="" class="p-1">
                                <i class="far fa-magnifying-glass text-base" style="color: #1235a5;" />
                            </el-button>
                        </el-tooltip>
                    </router-link> -->
                </el-button-group>
            </template>
        </el-table-column>
    </el-table>
</template>
<script>
import queryString from "query-string";
import moment from "moment";

export default {
    components: {
        MatchBullet: () => import("./AvstamningPaymentsStatusBullet.vue"),
    },

    props: {
        data: {
            type: Array,
            default: () => [],
        },
        selectedType: {
            type: String,
            default: "unmatched",
        },
    },

    watch: {
        data() {
            const { selectedPayment } = queryString.parse(window.location.search, { sort: false });
            if (!selectedPayment) return;
            const row = this.data.filter(item => item.id == selectedPayment)[0];
            this.rowSelected = row;
            this.$refs.table.setCurrentRow(row);

            // const selectedRow = document.getElementsByClassName("current-row");
            // this.$nextTick(() => {
            //     selectedRow[0].scrollIntoView();
            // });
        },

        selectedType() {
            this.tableVisible = false;
            this.$nextTick(() => {
                this.tableVisible = true;
            });
        },
    },

    data() {
        return {
            rowSelected: null,
            tableVisible: true,
        };
    },

    created() {
        // const { selectedPayment } = queryString.parse(window.location.search, { sort: false });
        // if (!selectedPayment) return;
        // setTimeout(() => {
        //     const row = this.data.filter(item => item.id == selectedPayment)[0];
        //     this.rowSelected = row;
        //     this.$refs.table.setCurrentRow(row);
        // const selectedRow = document.getElementsByClassName("current-row");
        // this.$nextTick(() => {
        //     selectedRow[0].scrollIntoView();
        // });
    },

    methods: {
        formatDate(date) {
            return moment(date).format("YYYY-MM-DD");
        },

        openCommentsModal(id) {
            this.$emit("openCommentsModal", id);
        },

        sortChange(event) {
            const query = queryString.parse(window.location.search, { sort: false });
            query.sortBy = event.order ? event.prop : undefined;
            query.sortByType = event.order || undefined;
            this.$router.replace({ query }).catch(() => {});
            this.$emit("sortTypeChanged");
        },

        tableRowClassName({ row }) {
            if (row.isMatched == false) {
                return "selected-row";
            }
            return "";
        },

        showMatchedInvoices(row) {
            const query = queryString.parse(window.location.search, { sort: false });
            console.log("🚀 - file: AvstamningPaymentsTable.vue:141 - showMatchedInvoices - query:", query);
            query.selectedPayment = row.id;
            console.log("🚀 - file: AvstamningPaymentsTable.vue:143 - showMatchedInvoices - row.id:", row.id);
            this.$router.replace({ query }).catch(() => {});

            this.rowSelected = row;
            this.$emit("paymentSelected", row.id);
        },

        handleCurrentChange() {
            this.$refs.table.setCurrentRow(this.rowSelected);
        },

        editPayment(id) {
            this.$emit("editPayment", id);
        },

        markAsUnidentified(id) {
            this.$emit("markAsUnidentified", id);
        },

        clearAsUnidentified(id) {
            this.$emit("clearAsUnidentified", id);
        },

        handleSelectionChange(val) {
            this.$emit(
                "selectionChanged",
                val.map(item => {
                    return {
                        id: item.id,
                        amount: item.amount,
                    };
                }),
            );
        },
    },
};
</script>
<style scoped>
.fa-stack-2x {
    font-size: 1.4em;
}
</style>
